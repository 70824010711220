import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import HeroSingle from '../components/pages/HeroSingle';
import Staff from '../components/homepage/Staff';
import GymIncluded from '../components/pages/GymIncluded';
import GymOwners from '../components/pages/GymOwners';
import Map from '../components/homepage/Map';
import SEO from '../components/seo';
import Footer from '../components/Footer';
import '../styles/app.scss';

const PrivatePlaydates = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = data.wordpressPage.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={data.wordpressPage.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[1].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
			
        <section className="privpldsec">
	      <div className="container smallestwdt">
		  <h2 class="bluetxt">Private Facility Rentals/Private Play Dates</h2>
		  <p>UP TO 10 CHILDREN. FOR ANY MORE THAN THE SPECIFIED NUMBER OF CHILDREN PER PACKAGE, A PER CHILD FEE WILL APPLY.<br /> INCLUDES USE OF GYM, ART ROOM, AND CALMING ROOM. CUSTOMERS CAN BRING SNACKS TO ENJOY IN THE LOBBY. CHILDCARE IS NOT INCLUDED WITH PRIVATE RENTALS. PARTY ROOM IS NOT INCLUDED IN PRIVATE RENTALS.
 <br /> PRIVATE PLAYDATES MUST BE RESERVED BY PHONE.</p>
		  <h3>Packages Available</h3>
		  <div className="packagesdiv">
		  
		  <div className="pricingbox bg-primary text-white">
<h2><span className="yellowtxt">PRIVATE PLAYDATES</span></h2>
<ul className="startxt starlistspacing">
<li>PRIVATE 90 MINUTES PLAY DATE <span class="yellowtxt">$145</span> <br /> 3 PACK OF 90 MINUTE PRIVATE PLAY DATES <span class="yellowtxt">$375</span> ($60 SAVINGS)</li>
<li>PRIVATE 120 MINUTES PLAY DATE <span class="yellowtxt">$175</span> <br /> 3 PACK OF 120 MINUTES PRIVATE PLAY DATES <span class="yellowtxt">$425</span> ($100 SAVINGS)</li>
</ul>
</div>

		  </div>
		  
		  {/* <div className="one_half halfalone">
					<h5><span className="bluetxt">Private facility rental</span></h5>
					<iframe
                            src="https://werockthespectrumasheville.wrtsfranchise.com/privatefacilityrental.php"
                            title="appointment"
                            className="healcode_widgets"
                        />
						
					</div> */}
					
					
		  </div>
        </section>            
 
            <Footer />
        </>
    );
};

export default PrivatePlaydates;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            excerpt
            acf {
                section_about_content
                section_about_title
                owner_gallery {
                    id
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                gym_includes_title
                gym_includes_content
                gym_includes_gallery {
                    id
                    localFile {
                        publicURL
                        childImageSharp {
                            fluid {
                                src
                                srcWebp
                            }
                        }
                    }
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
